import { Row, Col, P } from "../../styled/globalStyles";
import { VscLoading } from 'react-icons/vsc';
import { Main } from '../../layout/Main'

export const IsLoading = () => {
  return ( 
    <Main>
      <main className="page-content"> 
        <div className="container-fluid"> 
          <Row className="row mt-5 px-4">   
            <Col className="col-12 text-center mt-5"> 
              <VscLoading size="50" style={{ marginBottom: '20px' }} />
              <P>Carregando</P>
            </Col>  
          </Row> 
        </div>  
      </main>  
    </Main>  
  )
}