import { Main } from "../../layout/Main";
import { Row, TitlePage, Strong } from "../../styled/globalStyles";
import Col from "react-bootstrap/Col";
import { Button, Table } from "../../components";
import { IoSearch, IoClose } from "react-icons/io5";
import { useQuery } from "react-query";
import { useAuth } from "../../context/auth/useAuth";
import { useEffect, useState } from "react";
import {
  listaLaboratorios,
  procurarMedicamento,
} from "../../services/medicamentosRequests";
import Select from "react-select";
import { ProdutoResult } from "./produtos";
import MedicamentosSelecionados from "./medicamentos_selecionados";
import { logActionMedicamentos } from "../../services/logsRequests";
import { WarningBox } from "./components/warning_box";
import useWindowDimensions from "../../hooks/useWindowDimensions";

interface SelectProps {
  value: string;
  label: string;
}

export default function Medicamentos() {
  const { user } = useAuth();
  const [showSelected, setShowSelected] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [isSearched, setIsSearched] = useState(false);
  const [laboratorios, setLaboratorios] = useState<SelectProps[]>([]);
  const [produtosResult, setProdutosResult] = useState<ProdutoResult[]>([]);
  const [mark, setMark] = useState("");
  const [principioAtivo, setPrincipioAtivo] = useState("");
  const [lab, setLab] = useState<SelectProps>();
  const { height, width } = useWindowDimensions();

  const { data, isLoading, isError } = useQuery(["listaLaboratorios"], () =>
    listaLaboratorios()
  );

  useEffect(() => {
    const list: SelectProps[] = [];
    data?.data?.forEach((element: any) => {
      list.push({ value: element.id_laboratorio, label: element.laboratorio });
    });
    setLaboratorios(list);
  }, [data]);

  const handleSelect = (id: string, selected: boolean) => {
    setProdutosResult(
      produtosResult.map((item) =>
        item.id_produto === id
          ? {
              ...item,
              selected: !selected,
            }
          : item
      )
    );
  };

  const handleListSelect = () => {
    var selected: boolean = false;
    produtosResult.forEach((element) => {
      if (element.selected) selected = true;
    });
    return selected;
  };

  const handleReset = () => {
    setLab(null);
    setMark("");
    setPrincipioAtivo("");
  };

  const handleSearch = async () => {
    setIsSearching(true);
    setIsSearched(true);
    logActionMedicamentos({
      doctors_id: user?.doctorId,
      action: "Busca",
      brand: mark,
      active_principle: principioAtivo,
      laboratory: lab?.label,
    });
    const res = await procurarMedicamento({
      id_laboratorio: lab?.value,
      produto_termo: mark.length >= 3 ? mark : null,
      principioativo_termo: principioAtivo.length >= 3 ? principioAtivo : null,
    });
    if (res?.status) {
      setProdutosResult(res?.data);
    }
    setIsSearching(false);
  };

  const handleShowProductSelected = async () => {
    setShowSelected(!showSelected);
    produtosResult.forEach((element) => {
      if (element.selected) {
        logActionMedicamentos({
          doctors_id: user?.doctorId,
          action: "Detalhes",
          brand: element.produto,
          active_principle: null,
          laboratory: element.laboratorio,
        });
      }
    });
  };

  const columns = [
    {
      name: "Produto",
      selector: ({ produto }: any) => produto,
    },
    {
      name: "Laboratório",
      selector: ({ laboratorio }: any) => laboratorio,
      className: "text-center",
    },
    {
      name: "Tipo",
      selector: ({ tipo_produto_descricao }: any) => tipo_produto_descricao,
      className: "text-center",
    },
    {
      name: "Selecione",
      selector: ({ id_produto, selected }: any) => (
        <input
          type="checkbox"
          checked={selected}
          onChange={() => handleSelect(id_produto, selected)}
        />
      ),
      className: "text-center",
      style: { width: "10%" },
    },
  ];

  const showSelectedComponent = () => {
    const data: ProdutoResult[] = [];
    produtosResult.forEach((element) => {
      if (element.selected) data.push(element);
    });
    return (
      <MedicamentosSelecionados
        goBack={handleShowProductSelected}
        produtos={data}
      />
    );
  };
  return (
    <Main>
      <main className="page-content">
        {showSelected ? (
          showSelectedComponent()
        ) : (
          <div className="container-fluid">
            <Row className="row mt-4 p-4">
              <Col className="col col-md-6 d-flex align-items-center">
                <TitlePage>Buscar Medicamentos</TitlePage>
              </Col>
            </Row>
            <Row className="row px-4">
              <Col md={4} sm={12}>
                <Strong>Marca</Strong>
                <input
                  type="text"
                  className="form-control"
                  value={mark}
                  name="marca"
                  maxLength={50}
                  onChange={(event) => setMark(event.target.value)}
                  required
                />
                {mark.length > 0 && mark.length < 3 && (
                  <p style={{ color: "red" }}>
                    Marca deve ter mais de 3 digitos
                  </p>
                )}
              </Col>
              <Col md={3} sm={12}>
                <Strong>Princípio Ativo:</Strong>
                <input
                  type="text"
                  className="form-control"
                  value={principioAtivo}
                  name="principioAtivo"
                  maxLength={50}
                  onChange={(event) => setPrincipioAtivo(event.target.value)}
                  required
                />
              </Col>
              <Col md={3} sm={12}>
                <Strong>Laboratório:</Strong>
                <Select
                  options={laboratorios}
                  onChange={(item) => setLab(item)}
                  value={lab}
                  placeholder="Selecionar"
                />
              </Col>
              <Col className="col col-md-1 col-sm-12 d-flex justify-content-center">
                <br />
                <Row className="row px-4 mt-4">
                  <Col xs="auto">
                    <div style={{ paddingBottom: "2px" }}>
                      <Button
                        icon={<IoSearch size={20} />}
                        variant="primary"
                        size="large"
                        disabled={
                          (lab === undefined || lab === null) &&
                          mark.length < 3 &&
                          principioAtivo.length < 3
                        }
                        onClick={handleSearch}
                      />
                    </div>
                  </Col>
                  <Col xs="auto">
                    <Button
                      icon={<IoClose size={20} />}
                      variant="secondary"
                      size="large"
                      onClick={handleReset}
                    />
                  </Col>
                </Row>
              </Col>
              <Col className="col mt-4 col-md-1 col-sm-12 d-flex justify-content-center">
                <WarningBox />
              </Col>
            </Row>
            <br />
            <Row className="row px-4">
              <Col md={12}>
                {produtosResult.length > 0 || isSearched ? (
                  <Table
                    data={produtosResult}
                    columns={columns}
                    isLoading={isSearching}
                    onClickLine={(item) =>
                      handleSelect(item.id_produto, item.selected)
                    }
                    collection="produtos"
                  />
                ) : (
                  <div
                    style={{
                      height: `${
                        768 < width && height > 728 ? height * 0.5 : 250
                      }px`,
                    }}
                  ></div>
                )}
              </Col>
              <br />
              <Col className="col mt-4 col-md-12 d-flex justify-content-end ">
                <Button
                  label="Mais Informações dos Produtos Selecionados"
                  variant="primary"
                  size="large"
                  disabled={!handleListSelect()}
                  onClick={handleShowProductSelected}
                />
              </Col>
            </Row>
          </div>
        )}

        {/* <div style={{ position: 'fixed', bottom: 0, left: 0, padding: '5px', display: 'flex', flexDirection: 'column' }}>
          <br />
          <WarningBox />
        </div> */}
      </main>
    </Main>
  );
}
