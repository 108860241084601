import styled, { createGlobalStyle, css } from "styled-components";
import { theme } from "./theme";

const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
    text-decoration: none;
  }

  *::-webkit-scrollbar {
    width: 8px;
  }

  *::-webkit-scrollbar-thumb {
    background-color: ${theme.secondary[0]};  
  }
 
  a {
    text-decoration: none; 
    text-decoration-line: none !important;
  }
 
  button {
    text-decoration: none;  
  }

  body { 
    font-family: ${theme.fonts.montserrat};
    background: ${theme.background};
    font-size: 1.125rem;
    text-decoration: none; 
    overflow-x: hidden;
  }

  label {
    font-size: ${theme.fontSize.medium};
  }

`;

export const BodyRegister = styled.div`
  color: #fff;
`;

export const ContainerFluid = styled.div``;
export const Container = styled.div``;
export const Row = styled.div``;
export const Col = styled.div``;
export const Strong = styled.strong``;
export const Div = styled.div``;

interface ISpan {
  color?: string;
}
export const Span = styled.span<ISpan>`
  ${({ color }) => css` 
    color ${color}!important;
  `}
`;

interface Ip {
  color?: string;
  size?: string;
}
export const P = styled.p<Ip>`
  ${({ color, size }) => css`
    padding: 0;
    margin: 0; 
    color ${color}!important;
    font-size: ${size}
  `}
`;

interface IH1 {
  color?: string;
}
export const H1 = styled.h1<IH1>`
  font-size: ${theme.fontSize.large};
  font-weight: ${theme.fontWeight.large};
  padding: 0;
  margin: 0;

  ${({ color }) => css` 
    color ${color} !!important;
  `}
`;

interface ITitlePage {
  color?: string;
}
export const TitlePage = styled.h1<ITitlePage>`
  ${({ color }) => css`
    color: ${color ? color : theme.primary[0]};
  `}

  font-size: ${theme.fontSize.large};
  font-weight: ${theme.fontWeight.large};
  padding: 0;
  margin: 0;

  p {
    color: #666;
    font-weight: normal;
  }
`;

export default GlobalStyle;
