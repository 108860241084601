import http from "./api";

export const getRepresentatives = async (laboratories_id: any) => {
  const { data } = await http.get(
    `/representatives?laboratories_id=${laboratories_id}`
  );

  return data;
};

export const getAllRepresentatives = async () => {
  const { data } = await http.get(`/representatives`);

  return data;
};

export const createRepresentatives = async (values: any) => {
  const { data } = await http.post("/representatives", values);

  return data;
};

export const getRepresentative = async (id: any) => {
  const { data } = await http.get(`/representatives?id=${id}`);

  return data;
};

export const updateRepresentative = async (values: any) => {
  const { data } = await http.post("/representatives", values);

  return data;
};

export const toogleStatus = async (userId: any, status: any) => {
  const { data } = await http.post("/representatives/status", {
    id: userId,
    online: status,
  });

  return data;
};

export const getZipeCodes = async (id: string) => {
  const { data } = await http.get(`/representatives/zipcodes?id=${id}`);

  return data;
};

export const postZipeCode = async (body: any) => {
  const { data } = await http.post(`/representatives/zipcodes`, body);

  return data;
};

export const deleteZipeCode = async (id: string) => {
  const { data } = await http.delete(`/representatives/zipcodes`, {
    data: {
      id: id,
    },
    headers: { Authorization: "***" },
  });

  return data;
};

export const getUFs = async (id: string) => {
  const { data } = await http.get(`/representatives/ufs?id=${id}`);

  return data;
};

export const postUFs = async (body: any) => {
  const { data } = await http.post(`/representatives/ufs`, body);

  return data;
};

export const deleteUFs = async (id: string) => {
  const { data } = await http.delete(`/representatives/ufs`, {
    data: {
      id: id,
    },
    headers: { Authorization: "***" },
  });

  return data;
};
