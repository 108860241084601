import React, { createContext, useCallback } from 'react';
import { FilterType, TableType } from './props';
import { useLocalStorage } from "react-use";

const DEFAULT_VALUE = {
  filters: {
    currentPage: 1,
    perPage: 10,
    search: '',
    order: 'id',
    totalPages: 1
  },
  update: () => {}
}

const FilterContext = createContext<TableType>(DEFAULT_VALUE);

const FilterProvider: React.FC = ({ children }) => {
  const [filters, setFilters] = useLocalStorage<FilterType>('filters', DEFAULT_VALUE.filters)

  const update = useCallback(
    (filters: FilterType) => {
      setFilters(filters);
    },
    [setFilters]
  )

  return (
    //@ts-ignore
    <FilterContext.Provider value={{ filters, update }}>
      {children}
    </FilterContext.Provider>
  )
}

export { FilterProvider };
export default FilterContext;