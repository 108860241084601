import styled from "styled-components";

export const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    width:'50%',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

export const TitleContainer = styled.div`
  text-align: center;
`;

export const ButtonContainer = styled.div`
  padding: 10px 15px;
`;