import { P, Row, Col, H1 } from '../../styled/globalStyles';
import { AiOutlineInbox } from 'react-icons/ai';
import { BiError } from 'react-icons/bi';
import { VscLoading } from 'react-icons/vsc';
import { WrapperTable } from './styled'

export interface IData {
  data?: object
  selectedItem?: string
  isLoading?: boolean
  isError?: boolean
  collection: string
  onClickLine?: (item: any) => void
  columns: any
}

export const Table = ({ data, isLoading, isError, columns, selectedItem, onClickLine }: IData) => {

  if (isLoading) {
    return (
      <Row className="row p-5">
        <Col className="col-12 text-center mt-5">
          <VscLoading size="50" style={{ marginBottom: '20px' }} />
          <P>Carregando</P>
        </Col>
      </Row>
    )
  }

  if (isError) {
    return (
      <Row className="row p-5">
        <Col className="col-12 text-center mt-5">
          <BiError size="50" style={{ marginBottom: '20px' }} />
          <P>Ops ... Algo deu errado, tente novamente.</P>
        </Col>
      </Row>
    )
  }

  if (data instanceof Array && data?.length === 0) {
    return (
      <Row className="row p-5">
        <Col className="col-12 text-center mt-5">
          <AiOutlineInbox size="50" style={{ marginBottom: '20px' }} />
          <H1>Nenhum registro encontrado.</H1>
        </Col>
      </Row>
    )
  }

  const validate = (itemIdSelected: string, idOfList: any, item: any) => {
    if (!itemIdSelected || !idOfList)
      return false

    return idOfList(item) === itemIdSelected
  }

  return (
    <WrapperTable className="box">
      <div className="box-header with-border">
        <P size="12px">{data instanceof Array && data?.length} Registro(s) encontrado</P>
      </div>
      <div className="box-body table-responsive">
        <table className="table table-striped table-hover table-bordered">
          <tbody>
            <tr>
              {
                columns.map((column: any) => (
                  <th key={column.name} scope="col" className={column.className}><P size="14px">{column.name}</P></th>
                ))
              }
            </tr>
            {
              data instanceof Array &&
              data.map(item => (
                <tr key={item.id}>
                  {
                    columns.map((column: any, index: any) => (
                      <td key={index}
                        onClick={() => { if (onClickLine) { onClickLine(item) } }} className={column.className}
                        style={validate(selectedItem, column?.id, item) ? { backgroundColor: '#CFECEE' } : column.style}><P size="14px">{column.selector(item)}</P></td>
                    ))
                  }
                </tr>
              ))
            }
          </tbody>
        </table>
      </div>
      {/* <Div className="box-footer clearfix">
        <Row className="row">
            <Col className="col">
              <Div style={{ display: 'flex' }}> 
                <select value={filters.perPage} onChange={(e:any) => update({
                  ...filters,
                  perPage: parseInt(e.target.value)
                })}> 
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="50">50</option>
                  <option value="100">100</option> 
                </select>
                <P className="px-2" size="16px"> por página</P>
              </Div> 
            </Col>
            <Col className="col">
              <ul className="pagination pagination-sm no-margin pull-right">
                <li>
                  <button 
                    data-abc="true"
                    onClick={() => update({
                      ...filters,
                      currentPage: filters.currentPage - 1
                    })}  
                    disabled={filters.currentPage === 1} 
                    >Anterior
                  </button>
                </li> 
                <li>
                  <button 
                    data-abc="true"
                    onClick={() => {
                      update({
                        ...filters,
                        currentPage: filters.currentPage + 1
                      });
                    }}
                    disabled={filters.totalPages === filters.currentPage ? true : false}
                  >
                    Próxima
                  </button>
                </li>
              </ul>  
            </Col> 
        </Row> 
      </Div> */}
    </WrapperTable>
  )
}