import styled from "styled-components";
import { theme } from "../../styled/theme";

export const HeaderMobile = styled.header`
  text-align: right;
  background: ${theme.primary[0]};
  padding: 13px;
  margin-bottom: 10px;
  display: none;
  
  svg {
    cursor: pointer;
  }

  @media(max-width: 900px) {
    display: block;
  }
`;

export const Avatar = styled.header`
  background: ${theme.primary[0]};
  padding: 16px;
  border-radius: 50px;
`;