import { Row, Col, P } from "../../styled/globalStyles";
import { VscLoading } from 'react-icons/vsc';

export const LoadingComponent = () => {
  return (
    <Row className="row mt-5 px-4">
      <Col className="col-12 text-center mt-5">
        <VscLoading size="50" style={{ marginBottom: '20px' }} />
        <P>Carregando</P>
      </Col>
    </Row>
  )
}