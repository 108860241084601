import { Row, Col, P } from "../../styled/globalStyles";
import { BiError } from "react-icons/bi";

export const ErrorComponent = () => {
  return (
    <Row className="row mt-5 px-4">
      <Col className="col-12 text-center mt-5">
        <BiError size="50" style={{ marginBottom: "20px" }} />
        <P>Ops ... Algo deu errado, tente novamente.</P>
      </Col>
    </Row>
  );
};
