import { theme } from "../../../styled/theme"


export const WarningBox = () => {
  return (
    <div style={{
      padding: 16,
      textAlign: 'center',
      backgroundColor: 'white',
      borderRadius: 8,
      height: '100px',
      width: '100px',
      fontSize: '16px',
      border: '1px solid ' + theme.primary
    }}>
      <a href="https://consultas.anvisa.gov.br/#/bulario/" target={'_blank'} >Consulte Bula Anvisa</a>
    </div>
  )
}