import { useState } from "react";
import { GoChevronDown, GoChevronUp } from 'react-icons/go';
import { Link } from "react-router-dom";
import styled from "styled-components";
import { theme } from "../../styled/theme";

 
export const useDropdownMenu = () => { 
  const [isClosed, setIsClosed] = useState(true)
   
  const DropdownMenu = ({ children, title, icon }: { children: any, title: string, icon: any }) => { 
    return ( 
      <Wrapper> 
        <li className="header-menu"  onClick={() => setIsClosed(!isClosed)}>
          <Link to="#" >
            <div className="icon">{icon}</div> 
            <span> 
              { title }  
            </span>
            { isClosed ? <GoChevronDown /> : <GoChevronUp /> }
          </Link>
        </li>  
        {
          !isClosed &&
          children
        } 
      </Wrapper>
    )
  }

  return {
    showDropdownMenu: () => setIsClosed(!isClosed),
    DropdownMenu
  }
} 

const Wrapper = styled.div` 
  /* background: ${theme.secondary[0]}; 
  border-top:1px solid ${theme.secondary[0]}; 
    */
  border-top: 1px solid #EEE;
  border-bottom: 1px solid #EEE;
`;