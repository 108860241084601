import { useContext, useEffect } from 'react';
import GlobalAppContext from '../../context/template/context';
import SideBar from '../../components/Sidebar/SideBarMenu';
import { Row, TitlePage, Col, P } from '../../styled/globalStyles';
import { Avatar, HeaderMobile } from './styled'
import { GiHamburgerMenu } from 'react-icons/gi'

import useWindowDimensions from '../../hooks/useWindowDimensions'
import { useAuth } from '../../context/auth/useAuth';
import logoMarca from '../../assets/images/logo.png'
import medico from '../../assets/images/medico-pc.png'
import { useHistory } from 'react-router-dom';
// import { useHistory } from 'react-router';
// import { toast } from 'react-toastify';

export const Main = ({ children }: { children: any }) => {
  const history = useHistory();
  const { user } = useAuth();
  const { toggled, setToggled } = useContext(GlobalAppContext);
  const { width } = useWindowDimensions();

  let style = toggled ? "toggled" : "";

  useEffect(() => {
    width < 900 ? setToggled(false) : setToggled(true);

  }, [width])

  // useEffect(() => {
  //   if (parseInt(user?.profiles_id) === 2 && parseInt(user?.full_registration) === 0) {
  //     //toast.info('Complete seu cadastro para continuar utilizando a plataforma.');
  //     history.push('/perfil');
  //   }
  // }, [user])
  return (

    parseInt(user.profiles_id) === 2 ?
      <div className={"default-theme bg1"}>
        <Row className="row p-4" >
          <Col className="col col-md-8 d-flex align-items-start">
            <Row className="row">
              <Col className="col col-md-3 col-xs-3 col-sm-3 col-lg-3 d-flex justify-content-start">
                <Avatar>
                  <img src={medico} width={50} />
                </Avatar>
              </Col>
              <Col style={{ paddingTop: '20px' }} className="col col-md-9 col-xs-9 col-sm-9 col-lg-9 justify-content-start">
                <TitlePage style={{ cursor: 'pointer' }} onClick={() => history.push('/perfil')}>{user?.name ?? ''}</TitlePage>
              </Col>
            </Row>
          </Col>
          <Col onClick={() => history.push('/home')} className="col col-md-4 d-flex justify-content-end">
            <img src={logoMarca} height={70} />
          </Col>

        </Row>
        {children}
      </div> :
      <div className={"page-wrapper default-theme bg1 " + style}>
        <HeaderMobile>
          <GiHamburgerMenu
            onClick={() => setToggled(!toggled)}
            size={30}
            color="#FFF"
          />
        </HeaderMobile>
        <SideBar />
        {children}
      </div>
  )
}