import styled, { css } from "styled-components";
import { IButton } from './props'
import { theme } from '../../styled/theme'

export type DefaultButtonProps = {} & Pick<IButton, 'disabled' | 'isFull' | 'variant' | 'size'>

const modifiers = {
  isFull: () => css`
    width: 100%;
  `,

  light: () => css`
    background: ${theme.background};
    color: ${theme.primary[0]};
  `,

  primary: () => css`
    background: ${theme.primary[0]};
    color: #FFF;
  `,
  
  secondary: () => css`
  background: ${theme.secondary[0]};
    color: #FFF;
  `,
  
  noStyle: () => css`
    background: none;
    color: #FFF;

    svg {
      margin-right: 5px;
      color: ${theme.secondary[0]}
    }
  `,
 
  small: () => css`
    padding: 0px 10px;
    font-size: ${theme.fontSize.small};
  `,
  medium: () => css`
    padding: 0px 25px;
    font-size: ${theme.fontSize.medium};
  `,
  large: () => css`
    padding: 5px 35px;
    font-size: ${theme.fontSize.large};
  `,
}

export const WrapperButton = styled.button<DefaultButtonProps>`
  ${({ theme, disabled, isFull, variant, size }) => css`
    border: none;
    background: none;
    border-radius: 5px;
    font-weight: ${theme.fontWeight.medium};
    
    ${variant === 'light' && modifiers.light() }
    ${variant === 'primary' && modifiers.primary() }
    ${variant === 'secondary' && modifiers.secondary() }
    ${variant === 'no-style' && modifiers.noStyle() }

    ${size === 'small' && modifiers.small()}
    ${size === 'medium' && modifiers.medium()}
    ${size === 'large' && modifiers.large()}

    ${isFull && modifiers.isFull()}
  `}

  :hover, :disabled {
    opacity: 0.7;
  } 
  
`; 

export const Svg = styled.svg`  
  #dots #dot1{
    animation: load 1s infinite;
  }

  #dots #dot2{
    animation: load 1s infinite;
    animation-delay: 0.2s;
  }

  #dots #dot3{
    animation: load 1s infinite;
    animation-delay: 0.4s;
  }

  @keyframes load{
    0%{
      opacity: 0;
    }
    50%{
      opacity: 1;
    }
    100%{
      opacity: 0;
    }
  }
 
`;