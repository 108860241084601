import { IButton } from "./props";
import { WrapperButton, Svg } from "./styled";

export const Button = ({
  title,
  loading = false,
  isFull = false,
  icon,
  disabled = false,
  variant = "light",
  size = "medium",
  label,
  type = "button",
  onClick,
  className = "",
  style = {},
}: IButton) => {
  return (
    <WrapperButton
      title={title}
      type={type}
      isFull={isFull}
      disabled={disabled === true || loading === true ? true : false}
      variant={variant}
      size={size}
      onClick={onClick}
      className={className}
      style={style}
    >
      {icon && !loading && icon}
      {loading && <IconLoading />}
      {label && !loading && label}
    </WrapperButton>
  );
};

export const IconLoading = () => {
  return (
    <Svg
      id="dots"
      height="20px"
      viewBox="0 0 132 58"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="dots" fill="#CCC">
          <circle id="dot1" cx="25" cy="30" r="13"></circle>
          <circle id="dot2" cx="65" cy="30" r="13"></circle>
          <circle id="dot3" cx="105" cy="30" r="13"></circle>
        </g>
      </g>
    </Svg>
  );
};
