import { useState } from "react";

import { Wrapper } from './styled'

export const useModal = () => {
  const [visible, setVisible] = useState<boolean>(false);

  const Modal = ({ children }: { children: any }) => {
    return (
      <Wrapper visible={visible}>
        {children}
      </Wrapper>
    )
  }

  return {
    Modal: Modal,
    showModal: () => setVisible(!visible),
    visible: visible
  }
}