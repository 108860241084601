import { AuthProvider } from './auth/context'
import { GlobalApprovider } from './template/context'
import { FilterProvider } from './filter/context'

const GlobalContext = ({ children }: { children : JSX.Element }) => {
  return ( 
    <AuthProvider>
      <GlobalApprovider>
        <FilterProvider>
          {children}
        </FilterProvider>
      </GlobalApprovider> 
    </AuthProvider>  
  )
}

export default GlobalContext;