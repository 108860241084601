import { Scrollbars } from "react-custom-scrollbars";
import { Link, useParams, useHistory } from "react-router-dom";
import { useAuth } from "../../context/auth/useAuth";
import { useDropdownMenu } from "../Sidebar/useDropdownMenu";
import { FiBookOpen, FiGrid, FiLink, FiUser, FiUsers } from "react-icons/fi";
import { GiMedicines } from "react-icons/gi";
import {
  FaBriefcaseMedical,
  FaLink,
  FaBoxOpen,
  FaPowerOff,
} from "react-icons/fa";
import { AiFillMediumSquare, AiOutlineContacts } from "react-icons/ai";
import {
  BsFileEarmarkMedicalFill,
  BsFilePost,
  BsFillFileMedicalFill,
  BsListOl,
  BsPercent,
  BsTypeBold,
} from "react-icons/bs";
import { BiRadioCircleMarked, BiLogOut } from "react-icons/bi";
import { RiSlideshowFill } from "react-icons/ri";
import { HiOutlineChat } from "react-icons/hi";
import { getFirstName, handleProfile } from "../../helpers/helpers";
import {
  MdOutlineHotelClass,
  MdPermPhoneMsg,
  MdOutlineMonitor,
} from "react-icons/md";
import { FaWhatsapp } from "react-icons/fa";

// import userAvatar from '../../assets/images/user.jpg'
import logoMarca from "../../assets/images/logo.png";
import { toogleStatus } from "../../services/representativesRequest";
import { toast } from "react-toastify";
import { useState } from "react";
import { logAction } from "../../services/logsRequests";

interface ParamTypes {
  id?: string;
  content_id?: string;
}

function SideBarMenu() {
  const page = window.location.pathname;
  const history = useHistory();
  let { id, content_id } = useParams<ParamTypes>();
  const { logout, user } = useAuth();
  const { DropdownMenu } = useDropdownMenu();
  const [active, setActive] = useState(
    window.localStorage.getItem("doctor-hub-representatives-status")
  );

  const handleLogout = () => {
    logout();
    history.push("/login");
  };

  const handleStatus = () => {
    let status: any = "";
    if (active === null) {
      window.localStorage.setItem("doctor-hub-representatives-status", "1");
      status = window.localStorage.getItem("doctor-hub-representatives-status");
    } else {
      if (active === "1") {
        status = "0";
      } else {
        status = "1";
      }
    }

    toogleStatus(user.id, status)
      .then(() => {
        window.localStorage.setItem(
          "doctor-hub-representatives-status",
          status
        );
        toast.success(
          `Status alterado para ${status === "1" ? "Ativo" : "Inativo"}`
        );
        setActive(status);
      })
      .catch(() => {
        toast.error("Algo deu errado, tente novamente");
      });
  };

  return (
    <nav id="sidebar" className="sidebar-wrapper">
      <div className="sidebar-content">
        <Scrollbars>
          <div className="sidebar-item sidebar-brand">
            <img src={logoMarca} width={80} />
          </div>
          <div className="sidebar-item sidebar-header d-flex flex-nowrap">
            {/* <img className="img-responsive img-rounded" src={user.avatar ? user.avatar : userAvatar} alt="User " /> */}
            {parseInt(user?.profiles_id) === 6 ? (
              <div className="user-pic">
                <FaPowerOff
                  title={active === "1" ? "Estou Online" : "Estou Offiline"}
                  size={45}
                  color={active === "1" ? "#279442" : "#DDD"}
                  onClick={() => handleStatus()}
                  style={{ cursor: "pointer" }}
                />
              </div>
            ) : null}
            <div className="user-info">
              <span className="user-name">{getFirstName(user.name)}</span>
              <span className="user-role">
                {handleProfile(parseInt(user.profiles_id))}
              </span>
            </div>
          </div>
          <div className=" sidebar-item sidebar-menu">
            <ul>
              <li className={`header-menu ${page === "/home" && `active`}`}>
                <Link to="/home">
                  <div className="icon">
                    <FiGrid />
                  </div>
                  <span>Home</span>
                </Link>
              </li>

              {parseInt(user.profiles_id) === 2 ? (
                <DropdownMenu title="Laboratórios" icon={<BsFilePost />}>
                  <li
                    className={`header-menu ${
                      page === "/laboratorios/associados" ? `active` : null
                    }`}
                  >
                    <Link to="/laboratorios/associados">
                      <div className="icon">
                        <BiRadioCircleMarked />
                      </div>
                      <span>Associados</span>
                    </Link>
                  </li>
                  <li
                    className={`header-menu ${
                      page === "/laboratorios/outros" ? `active` : null
                    }`}
                  >
                    <Link to="/laboratorios/outros">
                      <div className="icon">
                        <BiRadioCircleMarked />
                      </div>
                      <span>Outros</span>
                    </Link>
                  </li>
                </DropdownMenu>
              ) : null}

              {parseInt(user.profiles_id) === 2 ? (
                <li
                  className={`header-menu ${
                    page === "/medicamentos" ? `active` : null
                  }`}
                >
                  <Link to="/medicamentos">
                    <div className="icon">
                      <BsFillFileMedicalFill />
                    </div>
                    <span>Busca de Medicamentos</span>
                  </Link>
                </li>
              ) : null}

              {parseInt(user.profiles_id) === 2 && (
                <li
                  className={`header-menu ${
                    page === `/laboratorios/${id}/conteudos` ? `active` : null
                  }`}
                >
                  <Link to="/laboratorios/0/conteudos">
                    <div className="icon">
                      <FiBookOpen />
                    </div>
                    <span>Conteúdos</span>
                  </Link>
                </li>
              )}

              {parseInt(user.profiles_id) === 2 ? (
                <li
                  className={`header-menu ${
                    page === "/laboratorios/0/conteudos/128" ? `active` : null
                  }`}
                >
                  <Link
                    onClick={() => {
                      logAction(user?.doctorId, "Atendimento Premium", null);
                    }}
                    to="/laboratorios/0/conteudos/128"
                  >
                    <div className="icon">
                      <FaWhatsapp />
                    </div>
                    <span>Atendimento Premium</span>
                  </Link>
                </li>
              ) : null}

              {parseInt(user.profiles_id) === 2 ? (
                <li className={`header-menu`}>
                  <Link
                    onClick={() => {
                      window.open(
                        "https://api.whatsapp.com/send?phone=5511953571558&text=Ol%C3%A1,%20gostaria%20de%20falar%20com%20o%20farmac%C3%AAutico."
                      );
                    }}
                    to=""
                  >
                    <div className="icon">
                      <FaWhatsapp />
                    </div>
                    <span>Fale com o Farmacêutico</span>
                  </Link>
                </li>
              ) : null}

              {parseInt(user.profiles_id) === 2 ? (
                <li
                  className={`header-menu ${
                    page === "/interacoesMedicamentosas" ? `active` : null
                  }`}
                >
                  <Link
                    // onClick={
                    //   () => {
                    //     logAction(user?.doctorId, 'Benefícios', null)
                    //   }
                    // }
                    to="/interacoesMedicamentosas"
                  >
                    <div className="icon">
                      <BsFileEarmarkMedicalFill />
                    </div>
                    <span>Interações Medicamentosas</span>
                  </Link>
                </li>
              ) : null}

              {parseInt(user.profiles_id) === 2 ? (
                <li
                  className={`header-menu ${
                    page === "/laboratorios/0/conteudos/8" ? `active` : null
                  }`}
                >
                  <Link
                    onClick={() => {
                      logAction(user?.doctorId, "Tutorial", null);
                    }}
                    to="/laboratorios/0/conteudos/8"
                  >
                    <div className="icon">
                      <MdOutlineHotelClass />
                    </div>
                    <span>Tutorial</span>
                  </Link>
                </li>
              ) : null}

              {parseInt(user.profiles_id) === 2 ? (
                <li
                  className={`header-menu ${
                    page === "/laboratorios/0/conteudos/27" ? `active` : null
                  }`}
                >
                  <Link
                    onClick={() => {
                      logAction(user?.doctorId, "Links Úteis", null);
                    }}
                    to="/laboratorios/0/conteudos/27"
                  >
                    <div className="icon">
                      <FiLink />
                    </div>
                    <span>Links Úteis</span>
                  </Link>
                </li>
              ) : null}

              {parseInt(user.profiles_id) === 2 ? (
                <li
                  className={`header-menu ${
                    page === "/laboratorios/discounts" ? `active` : null
                  }`}
                >
                  <Link to="/laboratorios/discounts">
                    <div className="icon">
                      <BsPercent />
                    </div>
                    <span>Programa de Descontos</span>
                  </Link>
                </li>
              ) : null}

              {parseInt(user.profiles_id) === 2 ? (
                <li
                  className={`header-menu ${
                    page === "/laboratorios/0/conteudos/28" ? `active` : null
                  }`}
                >
                  <Link
                    onClick={() => {
                      logAction(user?.doctorId, "Benefícios", null);
                    }}
                    to="/laboratorios/0/conteudos/28"
                  >
                    <div className="icon">
                      <BsTypeBold />
                    </div>
                    <span>Benefícios</span>
                  </Link>
                </li>
              ) : null}

              {parseInt(user.profiles_id) === 1 ||
              parseInt(user.profiles_id) === 3 ||
              parseInt(user.profiles_id) === 4 ? (
                <DropdownMenu title="Conteúdos" icon={<BsFilePost />}>
                  <li
                    className={`header-menu ${
                      page === "/conteudo-tipos" ||
                      page === "/conteudo-tipos/cadastro" ||
                      page === `/conteudo-tipos/${id}/editar`
                        ? `active`
                        : null
                    }`}
                  >
                    <Link to="/conteudo-tipos">
                      <div className="icon">
                        <BiRadioCircleMarked />
                      </div>
                      <span>Tipos de Conteúdo</span>
                    </Link>
                  </li>
                  <li
                    className={`header-menu ${
                      page === "/categorias" ||
                      page === "/categorias/cadastro" ||
                      page === `/categorias/${id}/editar`
                        ? `active`
                        : null
                    }`}
                  >
                    <Link to="/categorias">
                      <div className="icon">
                        <BiRadioCircleMarked />
                      </div>
                      <span>Categorias</span>
                    </Link>
                  </li>
                  <li
                    className={`header-menu ${
                      page === "/subcategorias" ||
                      page === "/subcategorias/cadastro" ||
                      page === `/subcategorias/${id}/editar`
                        ? `active`
                        : null
                    }`}
                  >
                    <Link to="/subcategorias">
                      <div className="icon">
                        <BiRadioCircleMarked />
                      </div>
                      <span>Subcategorias</span>
                    </Link>
                  </li>
                  <li
                    className={`header-menu ${
                      page ===
                        `/laboratorios/${user.laboratories_id}/conteudos` ||
                      page === "/conteudos/cadastro" ||
                      page === `/conteudos/${id}/editar`
                        ? `active`
                        : null
                    }`}
                  >
                    <Link to="/conteudos">
                      <div className="icon">
                        <BiRadioCircleMarked />
                      </div>
                      <span>Conteúdos</span>
                    </Link>
                  </li>
                  {parseInt(user.profiles_id) === 1 ? (
                    <li
                      className={`header-menu ${
                        page === `/banners` ||
                        page === "/banners/cadastro" ||
                        page === `/banners/${id}/editar`
                          ? `active`
                          : null
                      }`}
                    >
                      <Link to="/banners">
                        <div className="icon">
                          <BiRadioCircleMarked />
                        </div>
                        <span>Banners</span>
                      </Link>
                    </li>
                  ) : null}
                  {parseInt(user.profiles_id) === 1 ? (
                    <li
                      className={`header-menu ${
                        page === `/discounts` ||
                        page === `/discountsList` ||
                        page === "/discounts/cadastro"
                          ? `active`
                          : null
                      }`}
                    >
                      <Link to="/discounts">
                        <div className="icon">
                          <BiRadioCircleMarked />
                        </div>
                        <span>Programa de Descontos</span>
                      </Link>
                    </li>
                  ) : null}
                </DropdownMenu>
              ) : null}

              {parseInt(user.profiles_id) === 1 ||
              parseInt(user.profiles_id) === 5 ? (
                <li
                  className={`header-menu ${
                    page === "/laboratorios" ||
                    page === "/laboratorios/cadastro" ||
                    page === `/laboratorios/${id}/editar` ||
                    page === `/laboratorios/${id}/conteudos/${content_id}`
                      ? `active`
                      : null
                  }`}
                >
                  <Link to="/laboratorios">
                    <div className="icon">
                      <GiMedicines />
                    </div>
                    <span>Laboratórios</span>
                  </Link>
                </li>
              ) : null}

              {parseInt(user.profiles_id) === 3 ? (
                <li
                  className={`header-menu ${
                    page === "/representantes" ||
                    page === "/representantes/cadastro" ||
                    page === `/representantes/${id}/editar`
                      ? `active`
                      : null
                  }`}
                >
                  <Link to="/representantes">
                    <div className="icon">
                      <FaBriefcaseMedical />
                    </div>
                    <span>Representantes</span>
                  </Link>
                </li>
              ) : null}

              {parseInt(user.profiles_id) === 3 ? (
                <li
                  className={`header-menu ${
                    page === "/links" || page === "/links/cadastro"
                      ? `active`
                      : null
                  }`}
                >
                  <Link to="/links">
                    <div className="icon">
                      <FaLink />
                    </div>
                    <span>Links</span>
                  </Link>
                </li>
              ) : null}

              {parseInt(user.profiles_id) === 1 ||
              parseInt(user.profiles_id) === 5 ? (
                <li
                  className={`header-menu ${
                    page === "/usuarios" ||
                    page === "/usuarios/cadastro" ||
                    page === `/usuarios/${id}/editar`
                      ? `active`
                      : null
                  }`}
                >
                  <Link to="/usuarios">
                    <div className="icon">
                      <FiUsers />
                    </div>
                    <span>Usuários</span>
                  </Link>
                </li>
              ) : null}

              {parseInt(user.profiles_id) === 1 ||
              parseInt(user.profiles_id) === 5 ? (
                <li
                  className={`header-menu ${
                    page === "/medicos" ||
                    page === "/medicos/cadastro" ||
                    page === `/medicos/${id}/editar`
                      ? `active`
                      : null
                  }`}
                >
                  <Link to="/medicos">
                    <div className="icon">
                      <AiFillMediumSquare />
                    </div>
                    <span>Médicos</span>
                  </Link>
                </li>
              ) : null}

              {parseInt(user.profiles_id) === 1 ? (
                <li
                  className={`header-menu ${
                    page === "/medicos" ||
                    page === "/medicos/cadastro" ||
                    page === `/medicos/${id}/editar`
                      ? `active`
                      : null
                  }`}
                >
                  <Link to="/representantes/gerenciar">
                    <div className="icon">
                      <AiOutlineContacts />
                    </div>
                    <span>Representantes</span>
                  </Link>
                </li>
              ) : null}

              {parseInt(user.profiles_id) === 1 ||
              parseInt(user.profiles_id) === 3 ? (
                <li
                  className={`header-menu ${
                    page === "/templates" ||
                    page === "/templates/cadastro" ||
                    page === `/templates/${id}/editar`
                      ? `active`
                      : null
                  }`}
                >
                  <Link to="/templates">
                    <div className="icon">
                      <BsListOl />
                    </div>
                    <span>Templates</span>
                  </Link>
                </li>
              ) : null}

              {parseInt(user.profiles_id) === 6 ? (
                <li
                  className={`header-menu ${
                    page === "/monitoramento" ? `active` : null
                  }`}
                >
                  <Link to="/monitoramento">
                    <div className="icon">
                      <MdOutlineMonitor />
                    </div>
                    <span>Monitoramento</span>
                  </Link>
                </li>
              ) : null}

              {parseInt(user.profiles_id) === 3 ? (
                <li
                  className={`header-menu ${
                    page === "/produtos" ||
                    page === "/produtos/cadastro" ||
                    page === `/produtos/${id}/editar`
                      ? `active`
                      : null
                  }`}
                >
                  <Link to="/produtos">
                    <div className="icon">
                      <FaBoxOpen />
                    </div>
                    <span>Produtos</span>
                  </Link>
                </li>
              ) : null}

              {parseInt(user.profiles_id) === 3 ? (
                <li
                  className={`header-menu ${
                    page === "/apresentacoes" ||
                    page === "/apresentacoes/cadastro" ||
                    page === `/apresentacoes/${id}/editar`
                      ? `active`
                      : null
                  }`}
                >
                  <Link to="/apresentacoes">
                    <div className="icon">
                      <RiSlideshowFill />
                    </div>
                    <span>Apresentações</span>
                  </Link>
                </li>
              ) : null}

              {parseInt(user.profiles_id) === 6 ? (
                <li className={`header-menu ${page === "/chat" && `active`}`}>
                  <Link to="/chat">
                    <div className="icon">
                      <HiOutlineChat />
                    </div>
                    <span>Chat</span>
                  </Link>
                </li>
              ) : null}

              <li className={`header-menu ${page === "/perfil" && `active`}`}>
                <Link to="/perfil">
                  <div className="icon">
                    <FiUser />
                  </div>
                  <span>Perfil</span>
                </Link>
              </li>

              {parseInt(user.profiles_id) === 2 ? (
                <li
                  className={`header-menu ${
                    page === "/laboratorios/0/conteudos/7" ? `active` : null
                  }`}
                >
                  <Link to="/laboratorios/0/conteudos/7">
                    <div className="icon">
                      <MdPermPhoneMsg />
                    </div>
                    <span>Contato</span>
                  </Link>
                </li>
              ) : null}

              <li className="header-menu">
                <Link to="#" onClick={() => handleLogout()}>
                  <div className="icon">
                    <BiLogOut />
                  </div>
                  <span>Sair</span>
                </Link>
              </li>
            </ul>
          </div>
        </Scrollbars>
      </div>
    </nav>
  );
}

export default SideBarMenu;
