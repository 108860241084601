import { useState } from "react";

import { Wrapper } from './styled'

interface ModalProps {
  visible: boolean
  children: any
}

export const Modal = ({ visible, children }: ModalProps) => {
  return (
    <Wrapper visible={visible}>
      {children}
    </Wrapper>
  )
}