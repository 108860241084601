import { ItemContainer, SelectedItemContainer } from "./styled";
import { Col, P, Row } from "../../styled/globalStyles";

interface SelectMultpleItensProps {
  itens: string[];
  itensSelected: string[];
  setUFsSelected: (value: string[]) => void;
}

export const SelectMultpleItens = ({
  itens,
  itensSelected,
  setUFsSelected,
}: SelectMultpleItensProps) => {
  const addItem = (item: string) => {
    if (!itensSelected.includes(item)) {
      setUFsSelected([...itensSelected, item]);
    }
  };

  const removeItem = (item: string) => {
    const itens = itensSelected.filter((e) => e !== item);
    setUFsSelected(itens);
  };

  return (
    <>
      <br />
      {itensSelected.length > 0 && <br />}
      <Row className="row">
        {itensSelected.map((item: string, index: number) => (
          <Col className="col-2">
            <SelectedItemContainer onClick={() => removeItem(item)} key={index}>
              <P color="white">{item} x</P>
            </SelectedItemContainer>
          </Col>
        ))}
      </Row>
      {itensSelected.length > 0 && <br />}
      <Row className="row">
        {itens.map((item: string, index: number) => (
          <Col className="col-2">
            <ItemContainer onClick={() => addItem(item)} key={index}>
              <P>{item}</P>
            </ItemContainer>
          </Col>
        ))}
      </Row>
    </>
  );
};
