import { createContext, useState } from 'react';
 
const GlobalAppContext = createContext(null);

const GlobalApprovider: React.FC = ({ children }) => {
  const [toggled, setToggled] = useState(true);
  const [hasBackground, setHasBackground] = useState(true);

  let style = toggled ? "toggled" : "";
  style += hasBackground ? " sidebar-bg" : "";

  return (
    //@ts-ignore
    <GlobalAppContext.Provider value={{toggled, setToggled, hasBackground, setHasBackground}} >{children}</GlobalAppContext.Provider>
  );
}

export { GlobalApprovider };
export default GlobalAppContext;