import styled from "styled-components";
import { theme } from "../../styled/theme";

export const SelectedItemContainer = styled.div`
  cursor: pointer;
  padding: 10 10 5 5;
  margin-bottom: 5px;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  background: ${theme.primary[0]};
`;

export const ItemContainer = styled.div`
  cursor: pointer;
  padding: 10 10 5 5;
  margin-bottom: 5px;
  border-radius: 10px;
  border: 1px solid ${theme.primary[0]};
  justify-content: center;
  align-items: center;
`;
