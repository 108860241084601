import { createContext, useCallback } from "react";
import { IAuthProvider, IContext, IUser } from "./types";
import { useLocalStorage } from "react-use";

const DEFAULT_VAUE = {
  user: {
    token: "",
    name: "",
    email: "",
    avatar: "",
    id: "",
    profiles_id: "",
    laboratories_id: "",
    main_user: "",
    full_registration: "",
    doctorId: "",
    phone: "",
  },
  authenticate: () => {},
  logout: () => {},
  update: () => {},
};

const AuthContext = createContext<IContext>({} as IContext);

const AuthProvider = ({ children }: IAuthProvider) => {
  const [user, setUser] = useLocalStorage<IUser>(
    "doctor-hub-auth",
    DEFAULT_VAUE.user
  );

  async function authenticate(
    user: IUser,
    fullRegistration: any,
    doctorId: any
  ) {
    setUser({
      token: user.token ? user.token : "",
      name: user.name,
      email: user.email,
      avatar: user.avatar ? user.avatar : "",
      id: user.id,
      profiles_id: user.profiles_id,
      laboratories_id: user.laboratories_id,
      main_user: user.main_user,
      full_registration: fullRegistration,
      doctorId: doctorId,
      //@ts-ignore
      phone: user?.doctor?.phone,
    });
  }

  function logout() {
    setUser({
      token: "",
      name: "",
      email: "",
      avatar: "",
      id: "",
      profiles_id: "",
      laboratories_id: "",
      main_user: "",
      full_registration: "",
      doctorId: "",
      phone: "",
    });

    window.localStorage.removeItem("doctor-hub-auth-token");
  }

  const update = useCallback(
    (user: IUser) => {
      setUser(user);
    },
    [setUser]
  );

  return (
    <AuthContext.Provider value={{ user, authenticate, logout, update }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthProvider };
export default AuthContext;
