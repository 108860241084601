export const theme = {
  background: '#F1F1F1',
  light: { 
    0: '#d2d3d5', 
  },
  dark: { 
    0: '#999797', 
  },
  primary: {
    0: '#4897EE', 
  },
  secondary: {
    0: '#F07E05', 
  }, 
  success: '#55BE6E',
  warning: '#FF8F39', 
  danger: '#E8505B', 
  fonts: { 
    montserrat: `'Montserrat', sans-serif`
  },
  fontSize: {
    small: '10px',
    medium: '15px',
    large: '18px',
    ultra: '25px'
  },
  fontWeight: {
    small: '400',
    medium: '600',
    large: '700'
  }
}; 